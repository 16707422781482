/**
 * Raw numbers
 * If you need them formatted, use the phone number pipe
 */
export interface PhoneHours {
  salesMF: string | undefined;
  salesSat: string | undefined;
}

export const PHONE_NUMBERS = {
  sales: 8557170022,
  customerSupport: 8552167674,
  claims: 8662042219,
};

export const PHONE_HOURS: PhoneHours = {
  salesMF: '7am - 6pm CT',
  salesSat: '8am - 3pm CT',
};

export const PHONE_HOURS_FORMATTED = {
  sales: 'Monday - Friday 7am to 6pm CT, Saturday 8am to 3pm CT',
};

export const PHONE_HOURS_CA: PhoneHours = {
  salesMF: '8am - 6pm PT',
  salesSat: undefined,
};

export const PHONE_HOURS_FORMATTED_CA = {
  sales: 'Monday - Friday 8am to 6pm PT',
};
